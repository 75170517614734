<template>
    <div>
        <v-dialog
            v-model="$store.state.dialog"
            :fullscreen="isfullscreen"
            width="800"
            persistent
            transition="dialog-top-transition"
        >
            <v-card flat class="card">
                <v-toolbar outlined flat color="grey lighten-4">
                    <v-toolbar-title>{{$store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل'}} {{this.$route.meta.single}}</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon @click="isfullscreen = !isfullscreen">
                        <v-icon small>{{ isfullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand" }}</v-icon>
                    </v-btn>
                    <v-btn @click="close()" icon>
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                    <v-container>
                        <v-card outlined>
                            <v-container>
                                <v-row>
                                    <!-- titel -->
                                    <v-col cols="12" md="12">
                                        <h4 class="mb-2">
                                            العنوان
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field
                                            v-model="item.title"
                                            placeholder="العنوان"
                                            color="info"
                                            :rules="[$global.state.required()]"
                                            required
                                            filled
                                            outlined
                                            hide-details="auto"
                                        />
                                    </v-col>

                                    <!-- description -->
                                    <v-col cols="12" md="12">
                                        <h4 class="mb-2">
                                            نص الرد
                                            <span class="required">*</span>
                                        </h4>
                                        <v-textarea
                                            v-model="item.content"
                                            required
                                            outlined
                                            :rules="[$global.state.required()]"
                                            filled
                                            rows="5"
                                            hide-details="auto"
                                            label="نص الرد "
                                            color="secondary"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>

                        <!-- actions -->
                        <v-col cols="12" md="12">
                            <v-row class="mt-2">
                                <v-btn
                                    type="submit"
                                    :loading="$global.state.loading"
                                    x-large
                                    color="secondary--text primary"
                                    class="btn"
                                    :disabled="!valid"
                                >حفظ</v-btn>
                                <v-btn
                                    @click="close()"
                                    x-large
                                    color="error"
                                    class="mr-2 btn"
                                    outlined
                                >إلغاء</v-btn>
                            </v-row>
                        </v-col>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    components: {},

    data() {
        return {
            valid: false,
            isfullscreen: false,
            item: {},
        };
    },

    mounted() {
        this.reset();
        this.$eventBus.$on("fill-fields", () => {
            this.item = this.$store.state.itemDetails;
        });
    },

    methods: {
        reset() {
            this.item = {
                title: "",
                content: "",
            };
        },

        submit() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                if (this.$store.state.itemDetails.dialogType == "add") {
                    this.addItem();
                } else {
                    this.editItem();
                }
            }
        },

        async addItem() {
            this.$global.state.loading = true;
            await this.$http
                .post(`${this.$route.meta.endPoint}`, this.item)
                .then((res) => {
                    this.$eventBus.$emit(`refresh`);
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success",
                    });
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        editItem() {
            this.$global.state.loading = true;
            this.$http
                .put(`${this.$route.meta.endPoint}/${this.item.id}`, this.item)
                .then((res) => {
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success",
                    });
                    this.$eventBus.$emit(`refresh`);
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        close() {
            this.$store.commit("setDialog");
            this.reset();
            this.resetValidation();
            this.$store.commit("setItemDetails", {});
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>


<style scoped>
.btn-delete-file {
    padding: 11px;
    font-weight: bold;
}
</style>
